import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { SecurityHeadersService } from './services/securityHeaders/security-headers.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})
export class AppComponent {
  cookieValue: string;

  constructor(
    translate: TranslateService,
    private cookieService: CookieService,
    public router: Router,
    private securityHeadersService: SecurityHeadersService) {

    //Initialize security headers
    this.securityHeadersService.initSecurityHeaders();

    //Solution For Fix Scroll Then Navigation Start
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationStart) {
        const footer = document.getElementById("footerId") as HTMLInputElement;
        footer.style.marginTop = "100rem";
      }
      else if (event instanceof NavigationEnd) {
        setTimeout(() => {
          const footer = document.getElementById("footerId") as HTMLInputElement;
          footer.style.marginTop = "0rem";
        }, 300)
      }
    })

    translate.setDefaultLang('en');
    this.cookieValue = this.cookieService.get('lang');
    if (this.cookieValue) {
      translate.use(this.cookieValue);
    } else {
      this.cookieService.set('lang', 'el', { path: '/', domain: location.host.includes('localhost') ? 'localhost' : environment.domainCookieLang });
      translate.use('el');
    }
  }

}