<div class="container">
    <div class="row">
        <div class="col-12">
            <!-- Title -->
            <h1 class="steps-title">{{'steps_select_card' | translate}}</h1>
            <!-- Number And Line -->
            <div class="d-flex">
                <button class="btn circle-step">1</button>
                <div class="line"></div>
            </div>
        </div>
        <div class="col-12 text-center mt-5">
            <!-- Text -->
            <p class="text" [innerHTML]="'step_1_text' | translate"></p>
            <!-- Normal Card -->
            <div class="bg-image" [ngClass]="{'selected-card' : normalCard, 'unselected-card' : !normalCard }"
                (click)="selectNormalCard()">
                <h1 class="pb-3">{{'step_1_normal_card_text' | translate}}</h1>
                <img src="assets/images/cardELPE.png" *ngIf="normalCard" />
                <img src="assets/images/cardColleague.png" *ngIf="!normalCard" />
            </div>
            <!-- Under Line --> 
            <div class="under-line-card"></div>
            <!-- Volvo Card -->
            <div class="bg-image" [ngClass]="{'selected-card' : volvoCard, 'unselected-card' : !volvoCard }"
                (click)="selectVolvoCard()">
                <h1 class="pb-3" [innerHTML]="'step_1_volvo_card_text' | translate"></h1>
                <img src="assets/images/myAcount/volvo-pressed.png" *ngIf="volvoCard"/>
                <img src="assets/images/myAcount/volvo-unpressed.png" *ngIf="!volvoCard"/>
            </div>
            <!-- Under Line -->
            <!-- <div class="under-line-card"></div> -->
            <!-- Peugeot Card -->
            <!-- <div class="bg-image" [ngClass]="{'selected-card' : peugeotCard, 'unselected-card' : !peugeotCard }"
                (click)="selectPeugeotCard()">
                <h1 class="pb-3" [innerHTML]="'step_1_peugeot_card_text' | translate"></h1>
                <img src="assets/images/myAcount/peugeot-pressed.png" *ngIf="peugeotCard"/>
                <img src="assets/images/myAcount/peugeot-unpressed.png" *ngIf="!peugeotCard"/>
            </div> -->
            <!-- Under Line -->
            <!-- <div class="under-line-card"></div> -->
            <!-- DS Card -->
            <!-- <div class="bg-image" [ngClass]="{'selected-card' : dsCard, 'unselected-card' : !dsCard }"
                (click)="selectDSCard()">
                <h1 class="pb-3" [innerHTML]="'step_1_DS_card_text' | translate"></h1>
                <img src="assets/images/myAcount/ds-pressed.png" *ngIf="dsCard"/>
                <img src="assets/images/myAcount/ds-unpressed.png" *ngIf="!dsCard"/>
            </div> -->
            <!-- Under Line -->
            <div class="under-line-card"></div>
            <!-- EKO-BP Card -->
            <div class="bg-image" [ngClass]="{'selected-card' : ekoBpCard, 'unselected-card' : !ekoBpCard }"
                (click)="selectEkoBpCard()">
                <h1 class="pb-3" [innerHTML]="'step_1_EKO_BP_card_text' | translate"></h1>
                <img src="assets/images/EKO_Card.png" />
            </div>

            
            <!-- Next Button -->
            <div class="pt-5">
                <button class="submit" (click)="selectedCardSubmit()">{{'submit_next' | translate}}</button>
            </div>
        </div>
    </div>
</div>