import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from 'src/app/services/dataService/data.service';

@Component({
  selector: 'app-step1-select-card',
  templateUrl: './step1-select-card.component.html',
  styleUrls: ['./step1-select-card.component.less']
})
export class Step1SelectCardComponent implements OnInit {
  normalCard: boolean = true;
  volvoCard: boolean = false;
  peugeotCard: boolean = false;
  dsCard: boolean = false;
  ekoBpCard: boolean = false;


  constructor(
    public router: Router,
    private dataService: DataService) { }

  ngOnInit(): void { }

  //Select Normal Card
  selectNormalCard() {
    this.normalCard = true;
    this.volvoCard = false;
    // no support for peugeot/ds
    // this.peugeotCard = false;
    // this.dsCard = false;
    this.ekoBpCard = false;
  }

  //Select Volvo Card
  selectVolvoCard() {
    this.normalCard = false;
    this.volvoCard = true;
    // this.peugeotCard = false;
    // this.dsCard = false;
    this.ekoBpCard = false;
  }

  //Select Peugeot Card
  /*   selectPeugeotCard() {
      this.normalCard = false;
      this.volvoCard = false;
      this.peugeotCard = true;
      this.dsCard = false;
      this.ekoBpCard = false;
    } */

  //Select DS Card
  /*   selectDSCard() {
      this.normalCard = false;
      this.volvoCard = false;
      this.peugeotCard = false;
      this.dsCard = true;
      this.ekoBpCard = false;
    } */

  //Select EkoBp Card
  selectEkoBpCard() {
    this.normalCard = false;
    this.volvoCard = false;
    // this.peugeotCard = false;
    // this.dsCard = false;
    this.ekoBpCard = true;
  }

  //Submit Button Check Selected Card
  selectedCardSubmit() {
    if (this.normalCard && !this.volvoCard && !this.ekoBpCard) {
      this.dataService.setSelectedCard('NormalCard');
    } else if (!this.normalCard && this.volvoCard && !this.ekoBpCard) {
      this.dataService.setSelectedCard('VolvoCard');
    } else if (!this.normalCard && !this.volvoCard && this.ekoBpCard) {
      this.dataService.setSelectedCard('EkoBpCard');
    }
    this.router.navigate(['/account/addData']);
  }
}