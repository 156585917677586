export const environment = {
  apiUrl: 'https://elpe-dev.arx.net',
  recapchaKey: "6LcDREIeAAAAAM9Yc1fUpIZ-hCmwVQ6AyPvrxx5l",
  environmentName: 'deva',
  domainCookieLang: '.elpe-dev.arx.net',
  production: false,
  googleMapsApiKey: 'AIzaSyBjaPDGFtkRtd56rAmEoWokUaI2oI0UIyI',
  enableBannerFeature: false,
  securityHeaders: {
    contentSecurityPolicy: `default-src 'self' *.googleapis.com *.stripe.com *.bootstrapcdn.com fonts.gstatic.com *.helleniqenergy.gr google.com www.google.com *.doubleclick.net; 
    img-src 'self' https: https://*.googleapis.com https://*.gstatic.com *.google.com *.googleusercontent.com data:; 
    frame-src *.google.com youtube.com www.youtube.com *.stripe.com;
    connect-src 'self' https://*.googleapis.com *.google.com https://*.gstatic.com data: blob:;
    script-src 'self' 'unsafe-inline' https: 'unsafe-eval' blob:; 
    style-src 'self' 'unsafe-inline' https://fonts.googleapis.com *.bootstrapcdn.com;
    worker-src blob:;`,
    cacheControl: 'no-store, max-age=0',
    strictTransportSecurity: 'max-age=31536000',
    xXssProtection: '1'
  }
};