import { Injectable, Inject } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { environment } from '../../../environments/environment';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class SecurityHeadersService {

  constructor(
    private meta: Meta,
    @Inject(DOCUMENT) private document: Document
  ) {}

  initSecurityHeaders(): void {
    const headers = environment.securityHeaders;

    //Remove existing meta tags to avoid duplication
    this.meta.removeTag('http-equiv="Content-Security-Policy"');
    this.meta.removeTag('http-equiv="cache-control"');
    this.meta.removeTag('http-equiv="Strict-Transport-Security"');
    this.meta.removeTag('http-equiv="X-XSS-Protection"');

    //Add security headers
    this.meta.addTags([
      { httpEquiv: 'Content-Security-Policy', content: headers.contentSecurityPolicy },
      { httpEquiv: 'cache-control', content: headers.cacheControl },
      { httpEquiv: 'Strict-Transport-Security', content: headers.strictTransportSecurity },
      { httpEquiv: 'X-XSS-Protection', content: headers.xXssProtection }
    ]);
  }
}
