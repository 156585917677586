import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-how-to-charge',
  templateUrl: './how-to-charge.component.html',
  styleUrls: ['./how-to-charge.component.less'],
})
export class HowToChargeComponent implements OnInit, OnDestroy {
  appVideo: any;
  currentSection: string = '';
  translateSubscription!: Subscription;

  expandedSections: { [key: string]: boolean } = {
    'charge-go-app': true,
    'rfid-card': true,
    'credit-card': true,
    'gas-station': true,
  };

  sectionIds: string[] = ['charge-go-app', 'rfid-card', 'credit-card', 'gas-station'];

  isManualNavigation: boolean = false;
  private scrollTimeoutId: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public sanitizer: DomSanitizer,
    private translate: TranslateService,
  ) { }

  ngOnInit(): void {
    this.loadVideo();

    this.translateSubscription = this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.loadVideo();
    });

    const fragment = this.route.snapshot.fragment;
    if (fragment) {
      this.router.navigate([], { fragment: undefined, replaceUrl: true });
    }
    this.currentSection = '';
  }

  ngOnDestroy(): void {
    if (this.translateSubscription) {
      this.translateSubscription.unsubscribe();
    }
  }

  loadVideo(): void {
    this.translate.get('how_to_charge.app_video').subscribe((videoUrl: string) => {
      this.appVideo = this.sanitizer.bypassSecurityTrustResourceUrl(videoUrl);
    });
  }

  onNavLinkClick(sectionId: string): void {
    if (!this.expandedSections[sectionId]) {
      this.toggleExpand(sectionId);
    }
    this.isManualNavigation = true;
  }

  toggleExpand(sectionId: string): void {
    this.expandedSections[sectionId] = !this.expandedSections[sectionId];
  }

  @HostListener('window:scroll', [])
  onWindowScroll(): void {
    if (this.isManualNavigation) {
      clearTimeout(this.scrollTimeoutId);
      this.scrollTimeoutId = setTimeout(() => {
        this.isManualNavigation = false;
        this.updateCurrentSection();
      }, 100);
      return;
    }
    this.updateCurrentSection();
  }

  private updateCurrentSection(): void {
    const scrollPosition = window.pageYOffset;
    const navbarHeight = 120;
    for (let id of this.sectionIds) {
      const element = document.getElementById(id);
      if (element) {
        const elementTop = element.offsetTop - navbarHeight;
        const elementHeight = element.offsetHeight;
        if (
          scrollPosition >= elementTop - 100 &&
          scrollPosition < elementTop + elementHeight - 100
        ) {
          this.currentSection = id;
          break;
        }
      }
    }
  }
}