
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader, TranslatePipe } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CookieService } from 'ngx-cookie-service';
import { appInitializer,getRFIDSendCardInfoInitializer } from './interceptors/app.initializer';
import { UserService } from './services/userService/user.service';
import { StripeModule } from "stripe-angular"
import { MomentPipe } from './pipes/moment/moment.pipe'
import { AgmCoreModule } from '@agm/core';
import { RecaptchaFormsModule, RecaptchaModule } from 'ng-recaptcha';

//Routes
import { AppRoutingModule } from './app-routing.module';
import { HomeComponent } from './routes/home/home.component';
import { ContactComponent } from './routes/contact/contact.component';
import { LocationComponent } from './routes/location/location.component';
import { ResetPasswordValidationComponent } from './routes/reset-password-validation/reset-password-validation.component';
import { RegisterValidationComponent } from './routes/register-validation/register-validation.component';
import { Step1SelectCardComponent } from './routes/profile-Routes/step1-select-card/step1-select-card.component';
import { Step2AddDataComponent } from './routes/profile-Routes/step2-add-data/step2-add-data.component';
import { Step3SmsVerificationComponent } from './routes/profile-Routes/step3-sms-verification/step3-sms-verification.component';
import { Step4AddBankCardComponent } from './routes/profile-Routes/step4-add-bank-card/step4-add-bank-card.component';
import { Step4AddEkoBpCardComponent } from './routes/profile-Routes/step4-add-eko-bp-card/step4-add-eko-bp-card.component';
import { WelcomeComponent } from './routes/profile-Routes/welcome/welcome.component';
import { MyAcountComponent } from './routes/profile-Routes/my-acount/my-acount.component';
import { MyChargingRecordsComponent } from './routes/profile-Routes/my-charging-records/my-charging-records.component';
import { ChangePersonalInfoComponent } from './routes/profile-Routes/change-personal-info/change-personal-info.component';
import { SmsVerificationComponent } from './routes/profile-Routes/change-personal-info/sms-verification/sms-verification.component';
import { OneTimePaymentEKOComponent } from './routes/profile-Routes/one-time-payment-eko/one-time-payment-eko.component';
import { HowToChargeComponent } from './routes/how-to-charge/how-to-charge.component';

//Components
import { AppComponent } from './app.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { FooterComponent } from './components/footer/footer.component';
import { MapComponent } from './components/map/map.component';
import { LoginDialogComponent } from './components/dialogs/login-dialog/login-dialog.component';
import { ForgotPasswordDialogComponent } from './components/dialogs/forgot-password-dialog/forgot-password-dialog.component';
import { RegisterDialogComponent } from './components/dialogs/register-dialog/register-dialog.component';
import { LogoutDialogComponent } from './components/dialogs/logout-dialog/logout-dialog.component';
import { StartOverComponent } from './components/dialogs/start-over/start-over.component';
import { PayDeptDialogComponent } from './components/dialogs/deptDialogs/pay-dept-dialog/pay-dept-dialog.component';
import { SuccesChangeCardComponent } from './components/dialogs/succes-change-card/succes-change-card.component';
import { SelectPayEKOCardDeptDialogComponent } from './components/dialogs/deptDialogs/select-pay-eko-card-dept-dialog/select-pay-eko-card-dept-dialog.component';
import { PayWithEKOCardDeptDialogComponent } from './components/dialogs/deptDialogs/pay-with-eko-card-dept-dialog/pay-with-eko-card-dept-dialog.component';
import { ErrorSnakBarComponent } from './components/dialogs/error-snak-bar/error-snak-bar.component';
import {LocationPrinterComponent} from './components/locationPrinter/locationPrinter.component';
import { MigrationInfoComponent } from './routes/migration-info/migration-info/migration-info.component';
// Migration Banner Component
// import { MigrationBannerComponent } from './components/migrationBanner/migration-banner/migration-banner.component';
//Materials
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ReactiveFormsModule } from '@angular/forms';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatPasswordStrengthModule } from '@angular-material-extensions/password-strength';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSnackBarModule } from '@angular/material/snack-bar';

import { NgxPaginationModule } from 'ngx-pagination';
import { MatButtonModule } from '@angular/material/button';
import { MapSandbox } from './components/map/map.sandbox';
import {MatTableModule} from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { ActivityTranslationPipe } from './pipes/activityTranslator.pipe';
import { OpeningTimesPipePipe } from './pipes/openingTimesPipe.pipe';
import { environment} from '../environments/environment';
import { MigrationBannerComponent } from './components/migrationBanner/migration-banner/migration-banner.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ContactComponent,
    HowToChargeComponent,
    LocationComponent,
    ResetPasswordValidationComponent,
    RegisterValidationComponent,
    Step1SelectCardComponent,
    Step2AddDataComponent,
    Step3SmsVerificationComponent,
    Step4AddBankCardComponent,
    Step4AddEkoBpCardComponent,
    WelcomeComponent,
    MyAcountComponent,
    MyChargingRecordsComponent,
    ChangePersonalInfoComponent,
    SmsVerificationComponent,
    OneTimePaymentEKOComponent,
    NavbarComponent,
    FooterComponent,
    MapComponent,
    LoginDialogComponent,
    ForgotPasswordDialogComponent,
    RegisterDialogComponent,
    LogoutDialogComponent,
    StartOverComponent,
    PayDeptDialogComponent,
    SuccesChangeCardComponent,
    SelectPayEKOCardDeptDialogComponent,
    PayWithEKOCardDeptDialogComponent,
    ErrorSnakBarComponent,
    MomentPipe,
    ActivityTranslationPipe,
    OpeningTimesPipePipe,
    LocationPrinterComponent,
    ActivityTranslationPipe,
    LocationPrinterComponent,
    MigrationInfoComponent,
    //Migratio Banner Component commented out
    MigrationBannerComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    StripeModule.forRoot(""),
    RecaptchaFormsModule,
    RecaptchaModule,
    MatInputModule,
    MatFormFieldModule,
    MatSelectModule,
    MatCheckboxModule,
    ReactiveFormsModule,
    MatSidenavModule,
    MatIconModule,
    MatButtonModule,
    MatDialogModule,
    BrowserAnimationsModule,
    MatProgressSpinnerModule,
    MatPasswordStrengthModule,
    MatExpansionModule,
    MatSnackBarModule,
    NgxPaginationModule,
    MatTableModule,
    MatSortModule,
    AgmCoreModule.forRoot({
      //apiKey: 'AIzaSyAK9FaZH2MRFLsn0BaG02eQ85Fze53yplk' for production
      //apiKey :'AIzaSyBjaPDGFtkRtd56rAmEoWokUaI2oI0UIyI' //development key for localhosts
      apiKey : environment.googleMapsApiKey
    }),
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
    })
  ],
  providers: [{
    provide: APP_INITIALIZER,
    useFactory: appInitializer,
    multi: true, deps: [UserService]
  },
  {
    provide: APP_INITIALIZER,
    useFactory: getRFIDSendCardInfoInitializer,
    multi: true, deps: [UserService]
  },
  CookieService,
  MapSandbox],
  bootstrap: [AppComponent]
})
export class AppModule { }

// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient, './assets/i18n/', '.json');
}